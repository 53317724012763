import http from "../https";

export const changeCourseAvailableAsync = (
    courseId: number,
    costBeforeDiscount: number,
    cost: number,
    personalDiscount: number
) =>
    http.patch(`/api/courses/${courseId}/cost`, {
        costBeforeDiscount,
        personalDiscount,
        cost,
    });
