import http from "../https";

export const downloadLessonFilesAsync = (lessonId: string) =>
  http.get(
    `/api/lessons/${lessonId}/files`,
    {
      Accept: "application/zip",
    },
    { responseType: "arraybuffer" }
  );
