export const ApplicationsMarketplacegIcon = () => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
    >
      <g transform="translate(-80 -80)">
        <g id="Icon">
          <g transform="translate(0 -48)">
            <path d="m95 134c0-1.326-.527-2.598-1.464-3.536-.938-.937-2.21-1.464-3.536-1.464-1.287 0-2.713 0-4 0-1.326 0-2.598.527-3.536 1.464-.937.938-1.464 2.21-1.464 3.536v4c0 1.326.527 2.598 1.464 3.536.938.937 2.21 1.464 3.536 1.464h4c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536zm-2 0v4c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-4c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-4c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h4c.796 0 1.559.316 2.121.879.563.562.879 1.325.879 2.121z" />
          </g>
          <g transform="translate(0 -32)">
            <path d="m95 134c0-1.326-.527-2.598-1.464-3.536-.938-.937-2.21-1.464-3.536-1.464-1.287 0-2.713 0-4 0-1.326 0-2.598.527-3.536 1.464-.937.938-1.464 2.21-1.464 3.536v4c0 1.326.527 2.598 1.464 3.536.938.937 2.21 1.464 3.536 1.464h4c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536zm-2 0v4c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-4c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-4c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h4c.796 0 1.559.316 2.121.879.563.562.879 1.325.879 2.121z" />
          </g>
          <g transform="translate(16 -48)">
            <path d="m95 134c0-1.326-.527-2.598-1.464-3.536-.938-.937-2.21-1.464-3.536-1.464-1.287 0-2.713 0-4 0-1.326 0-2.598.527-3.536 1.464-.937.938-1.464 2.21-1.464 3.536v4c0 1.326.527 2.598 1.464 3.536.938.937 2.21 1.464 3.536 1.464h4c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536zm-2 0v4c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-4c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-4c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h4c.796 0 1.559.316 2.121.879.563.562.879 1.325.879 2.121z" />
          </g>
          <g transform="translate(16 -32)">
            <path d="m95 134c0-1.326-.527-2.598-1.464-3.536-.938-.937-2.21-1.464-3.536-1.464-1.287 0-2.713 0-4 0-1.326 0-2.598.527-3.536 1.464-.937.938-1.464 2.21-1.464 3.536v4c0 1.326.527 2.598 1.464 3.536.938.937 2.21 1.464 3.536 1.464h4c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536zm-2 0v4c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-4c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-4c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h4c.796 0 1.559.316 2.121.879.563.562.879 1.325.879 2.121z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
