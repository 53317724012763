import http from "../https";

import { downloadCSV } from "src/shared/helpers";

type getApllicationsCSVParams = {
  search?: string;
  status?: string;
  type?: string;
};

export const getApllicationsCSV = async (params: getApllicationsCSVParams) => {
  const response = await http.get(
    "/api/applications/csv",
    {
      responseType: "text/csv",
    },
    { params }
  );

  return downloadCSV(response.data, "applications_training.csv");
};
